// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-work-layout-js": () => import("./../../../src/components/work-layout.js" /* webpackChunkName: "component---src-components-work-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-css-art-gengar-halloween-index-js": () => import("./../../../src/pages/css_art/gengar-halloween/index.js" /* webpackChunkName: "component---src-pages-css-art-gengar-halloween-index-js" */),
  "component---src-pages-css-art-index-js": () => import("./../../../src/pages/css_art/index.js" /* webpackChunkName: "component---src-pages-css-art-index-js" */),
  "component---src-pages-css-art-pikachu-index-js": () => import("./../../../src/pages/css_art/pikachu/index.js" /* webpackChunkName: "component---src-pages-css-art-pikachu-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */)
}

